/* 
  ****************
  ** Separators **
  ****************

  - |

*/
.react-img-editor-toolbar-separator { background-color: rgba(255, 105, 97, 0.55); }



/*
  ***********
  ** Icons **
  ***********
*/
.react-img-editor-toolbar-icon { transition: all 0.3s ease; cursor: pointer; }
.react-img-editor-toolbar-icon:hover {  transform: translateY(-5px); }

.react-img-editor-toolbar-icon i,
.react-img-editor-toolbar-icon.disabled i {
  color: #f6f6ff;
  background-color: rgba(255, 105, 97, 0.55);
}

.react-img-editor-toolbar-icon.activated i {
  color: rgba(255, 105, 97, 0.55);
  background-color: #f6f6ff;
}

.react-img-editor-toolbar-icon:hover i {
  transform: translateY(-5px);
  background-color: rgba(255, 105, 97, 0.55);
}

.react-img-editor-toolbar-icon i {
  transform: translateY(-5px);
  background-color: rgba(255, 105, 97, 0.55);
  padding: 3px;
  border-radius: 5px;
}



/*
  *************
  ** Tooltip **
  *************

  - Options Tooltips
*/
.react-img-editor-tooltip { z-index: 9999; color:rgba(54, 54, 54, 0.85); }
.react-img-editor-tooltip .rc-tooltip-arrow { color: rgba(54, 54, 54, 0.85); }

.react-img-editor-tooltip .rc-tooltip-inner {
  background-color: rgba(54, 54, 54, 0.85);
  border-radius: 20px;
  color: #ff0000;
}
.react-img-editor-color-square { border: none; }
.react-img-editor-line-type { color: #ff0000; }