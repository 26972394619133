.dot-elastic {
  position: relative;
  width: 7.5px;
  height: 7.5px;
  border-radius: 5px;
  background-color: #ff6961;
  color: #ff6961;
  animation: dot-elastic 1s infinite linear;
}

.dot-elastic::before, .dot-elastic::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-elastic::before {
  left: -15px;
  width: 7.5px;
  height: 7.5px;
  border-radius: 5px;
  background-color: #ff6961;
  color: #ff6961;
  animation: dot-elastic-before 1s infinite linear;
}

.dot-elastic::after {
  left: 15px;
  width: 7.5px;
  height: 7.5px;
  border-radius: 5px;
  background-color: #ff6961;
  color: #ff6961;
  animation: dot-elastic-after 1s infinite linear;
}

@keyframes dot-elastic-before {
  0% { transform: scale(1, 1); }
  25% { transform: scale(1, 1.5); }
  50% { transform: scale(1, 0.67); }
  75% { transform: scale(1, 1); }
  100% { transform: scale(1, 1); }
}
@keyframes dot-elastic {
  0% { transform: scale(1, 1); }
  25% { transform: scale(1, 1); }
  50% { transform: scale(1, 1.5); }
  75% { transform: scale(1, 1); }
  100% { transform: scale(1, 1); }
}
@keyframes dot-elastic-after {
  0% { transform: scale(1, 1); }
  25% { transform: scale(1, 1); }
  50% { transform: scale(1, 0.67); }
  75% { transform: scale(1, 1.5); }
  100% { transform: scale(1, 1); }
}
